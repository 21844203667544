<template>
  <section class="containerselectAllNotifications">
    <section v-if="!store.state.cardIdsLoading" class="containerselectAllNotifications__actions">
      <genericCheckBox v-model="modelIsSelected" @change="toggleAllNotificationsSelection($event)" :indeterminate="isIndeterminate" ref="selectAllCheck" label="Select All"></genericCheckBox>
      <span class="containerselectAllNotifications__actions--counter" v-if="store.state.counterSelectedNotifications">({{store.state.counterSelectedNotifications}} Notification<span v-if="areManyCardsSelected">s</span> selected)</span>
      <span class="containerselectAllNotifications__actions--modalButton" v-if="store.state.counterSelectedNotifications" @click="deleteSelected()">
        <span class="grey-icon-delete-notification button"></span>
        <span class="label">Delete</span>
      </span>
      <span class="containerselectAllNotifications__actions--modalButton" v-if="store.state.counterSelectedNotifications" @click="markAsReadSelected()">
        <span class="grey-icon-read button"></span>
        <span class="label">Mark as read</span>
      </span>
    </section>
    <section v-else class="containerselectAllNotifications__spinner">
      <spinner class="selectAllSpinner" />
    </section>
    <section class="containerselectAllNotifications__mobileModal" :style="{height: [store.state.counterSelectedNotifications > 0 ? '53px' : '0px']}">
      <span class="containerselectAllNotifications__mobileModal--counter">{{store.state.counterSelectedNotifications}} Notification<span v-if="areManyCardsSelected">s</span> selected</span>
      <span class="containerselectAllNotifications__mobileModal--separator">|</span>
      <span class="containerselectAllNotifications__actions--modalButton" v-if="store.state.counterSelectedNotifications" @click="deleteSelected()">
        <span class="grey-icon-delete-notification containerselectAllNotifications__mobileModal--icon"></span>
      </span>
      <span class="containerselectAllNotifications__actions--modalButton" v-if="store.state.counterSelectedNotifications" @click="markAsReadSelected()">
        <span class="grey-icon-read containerselectAllNotifications__mobileModal--icon"></span>
      </span>
      <span class="containerselectAllNotifications__actions--modalButton" v-if="store.state.counterSelectedNotifications" @click="resetSelected()">
        <span class="grey-icon-close containerselectAllNotifications__mobileModal--icon"></span>
      </span>
    </section>
  </section>
</template>

<script>
import { onMounted, computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'
import spinner from '@/components/spinner/spinner'

export default {
  name: 'selectAllNotifications',
  props: {
    totalNotifications: {
      type: Number
    }
  },
  components: {
    genericCheckBox,
    spinner
  },
  emits: [
    'notificationsDeleted',
    'notificationsMarkedAsRead'
  ],
  setup (props, { emit }) {
    const store = useStore()
    const selectedIds = ref([])
    const selectAllCheck = ref(null)
    const modelIsSelected = ref(false)

    /**
     * @description Flag to know if there are more than one card selected.
     */
    const areManyCardsSelected = computed(() => {
      return store.state.counterSelectedNotifications > 1
    })

    /**
     * @description Flag to indicate if all notifications are selected or just a couple of them.
     */
    const isIndeterminate = computed(() => {
      return store.state.counterSelectedNotifications > 0 && store.state.counterSelectedNotifications !== props.totalNotifications
    })

    onMounted(() => {
      updateSelectionBasedOnCounter(store.state.counterSelectedNotifications)
    })

    /**
     * @description Changes the value of select all checbox based on individual selections of each notification.
     * @param {newCounter} integer with the total of notifications selected.
     */
    watch(() => store.state.counterSelectedNotifications, (newCounter) => {
      updateSelectionBasedOnCounter(newCounter)
    })

    /**
     * @description Selects or deselects all the notifications.
     * @param {areAllNotificationsSelected} boolean to indicate if all notifications will be selected or not.
     */
    function toggleAllNotificationsSelection (areAllNotificationsSelected) {
      store.commit({
        type: 'CLEAN_NOTIFICATIONS_IDS_TO_ACTION'
      })
      store.commit({
        type: 'TOGGLE_ALL_NOTIFICATIONS_SELECTION',
        areAllNotificationsSelected: areAllNotificationsSelected,
        totalNotifications: props.totalNotifications
      })
      document.getElementsByClassName('containerMobileMenu__menu')[0].style.bottom = areAllNotificationsSelected ? '68px' : '34px'
    }

    /**
     * @description Updates the selection of the checkbox based on the number of selected notifications.
     * @param {counterSelected} number of selected notifications.
     */
    function updateSelectionBasedOnCounter (counterSelected) {
      let areAllNotificationsSelected
      if (!counterSelected) {
        areAllNotificationsSelected = false
        store.commit({
          type: 'CLEAN_NOTIFICATIONS_IDS_TO_ACTION'
        })
      } else if (store.state.selectAllNotificationsChecked) {
        areAllNotificationsSelected = counterSelected === props.totalNotifications - store.state.notificationsIdsToAction.length
      } else {
        areAllNotificationsSelected = counterSelected === props.totalNotifications + store.state.notificationsIdsToAction.length || store.state.notificationsIdsToAction.length === props.totalNotifications
      }
      store.commit({
        type: 'SELECT_ALL_NOTIFICATIONS_ACTIVE',
        areAllNotificationsSelected: areAllNotificationsSelected
      })
      modelIsSelected.value = Boolean(areAllNotificationsSelected)
    }

    /**
     * @description Delete selected notifications and refresh page
     */
    function deleteSelected () {
      emit('notificationsDeleted')
    }

    /**
     * @description Mark selected notifications as read
     */
    function markAsReadSelected () {
      emit('notificationsMarkedAsRead')
    }

    function resetSelected () {
      store.commit({
        type: 'CLEAN_NOTIFICATIONS_IDS_TO_ACTION'
      })
      store.commit({
        type: 'TOGGLE_ALL_NOTIFICATIONS_SELECTION',
        areAllNotificationsSelected: false,
        totalNotifications: 0
      })
    }

    return {
      store,
      selectedIds,
      selectAllCheck,
      areManyCardsSelected,
      isIndeterminate,
      toggleAllNotificationsSelection,
      modelIsSelected,
      deleteSelected,
      markAsReadSelected,
      resetSelected
    }
  }
}
</script>
