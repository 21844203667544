<template>
  <section class="containerSkeletonNotifications">
    <div class="containerSkeletonNotifications__card" v-for="i in items" :key="i">
      <div class="image"></div>
      <div class="content">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </section>
</template>

<script>

import { ref } from 'vue'

export default {
  name: 'skeletonNotifications',

  setup (props) {
    const items = ref(12)

    return {
      items
    }
  }
}
</script>
