<template>
  <section class="containerNotifications">
    <section class="containerNotifications__info">
      <backButton altRedirection="resultsSearch"></backButton>
      <div class="containerNotifications__info--title">
        <span class="label">Notifications</span>
      </div>
      <div class="containerNotifications__actions">
        <selectAllNotifications v-if="notificationsLoaded && notifications.length" :totalNotifications="notificationsTotal" @notificationsDeleted="deleteSelected" @notificationsMarkedAsRead="markSelectedAsRead"/>
        <div class="containerNotifications__actions--filter">
          <filterPerAppNotifications v-show="notificationsLoaded" @launch-search="filterSelected" />
          <filterPerStatusNotifications v-show="notificationsLoaded" @launch-search="filterSelected" />
        </div>
      </div>
      <section v-if="notificationsLoaded && notifications.length" class="containerNotifications__info--list">
        <div v-for="(item, index) in notifications" :key="index"
          class="item"
          :class="[{'noTitle': !item.title}, {'itemSelected': itemIsSelected(item)}]"
          @click="openDetailPage(item)">
          <div class="item__content">
            <div class="item__content--media">
              <div class="item__content--unread">
                <genericCheckBox :modelValue="itemIsSelected(item)" @change="handleSelectClick($event, item)" :style="{opacity: [itemIsSelected(item) ? '1' : ''] }"/>
                <span class="dot" v-if="!item.isRead">.</span>
              </div>
              <img class="item__content--image" :src="item.thumbnailUrl" />
            </div>
            <div class="item__content--info" :class="{'itemRead': item.isRead}">
              <span class="item__content--header" style="-webkit-box-orient: vertical;">{{item.header}}</span>
              <span class="item__content--title" v-if="item.title" style="-webkit-box-orient: vertical;">{{item.title}}</span>
              <span class="item__content--subTitle" v-if="item.subtitle" style="-webkit-box-orient: vertical;">{{item.subtitle}}</span>
              <span class="item__content--timeAgo">{{store.getters.formatTimeAgo(item.notificationDate)}}</span>
            </div>
          </div>
        </div>
      </section>
      <section v-if="notificationsLoaded && !notifications.length" class="containerNotifications__info--noNotifications">
        <img class="noNotificationImage" src="@/assets/notificacion-01.svg" />
        <span class="label">You don’t have any notification.</span>
      </section>
      <skeletonNotifications v-if="!notificationsLoaded"></skeletonNotifications>
      <paginator v-if="notificationsLoaded"
        :totalItems="notificationsTotal"
        :maxPages="maxPages"
        :pagination="store.state.notificationsPagination"
        @goToPage="goToPage"
      >
      </paginator>
    </section>
  </section>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import backButton from '@/components/backButton/backButton'
import paginator from '@/components/paginator/paginator'
import skeletonNotifications from '@/components/skeletons/skeletonNotifications/skeletonNotifications'
import options from './options.json'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'
import selectAllNotifications from '@/components/selectAllNotifications/selectAllNotifications'
import filterPerAppNotifications from '@/components/filterPerAppNotifications/filterPerAppNotifications'
import filterPerStatusNotifications from '@/components/filterPerStatusNotifications/filterPerStatusNotifications'

export default {
  name: 'notifications',
  components: {
    backButton,
    paginator,
    skeletonNotifications,
    genericCheckBox,
    selectAllNotifications,
    filterPerAppNotifications,
    filterPerStatusNotifications
  },

  setup (props) {
    const store = useStore()
    const route = useRoute()
    const notificationsLoaded = ref(false)
    const notifications = ref([])
    const notificationsTotal = ref()
    const maxPages = ref(6)
    const pageSize = ref(12)

    onMounted(() => {
      window.onpopstate = function () {
        updateStorePagination(route.query.k, route.query.t)
        loadNotifications()
      }
      updateStorePagination(route.query.k, route.query.t)
      loadNotifications()
      resizeSetUp()
      handleResize()
    })

    /**
     * @description Validate if the item should be checked or not
     */
    const itemIsSelected = computed(() => (item) => {
      let isSelected
      if (store.state.selectAllNotificationsChecked && store.state.notificationsIdsToAction.length !== notificationsTotal.value) {
        isSelected = store.state.notificationsIdsToAction.find(id => item.id === id) === undefined
      } else {
        isSelected = store.state.notificationsIdsToAction.find(id => item.id === id) !== undefined
      }
      return isSelected
    })

    /**
     * @description Handler to know the size of the screen
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
      })
    }

    /**
    * @description Handler to know the size of the screen
    */
    function resizeSetUp () {
      if ((window.innerWidth <= 576) && (window.innerWidth > 0)) {
        maxPages.value = 3
      } else {
        maxPages.value = 6
      }
    }

    /**
     * @description Load the list of notifications
     */
    function loadNotifications () {
      notificationsLoaded.value = false
      store.dispatch({
        type: 'getNotifications',
        page: store.state.notificationsPagination.page,
        size: store.state.notificationsPagination.size
      }).then(response => {
        notifications.value = response.data.results
        notificationsTotal.value = response.data.total
        notificationsLoaded.value = true
      })
    }

    /**
     * @description Reload notifications after deleting one or more notifications
     */
    function delayedGetNotifications () {
      setTimeout(() => {
        store.commit({
          type: 'CLEAN_NOTIFICATIONS_IDS_TO_ACTION'
        })
        loadNotifications()
      }, 3000)
    }

    /**
     * @description open a new tab with the selected item
     * @param item notification item.
     */
    function openDetailPage (item) {
      if (!item.isRead) {
        store.dispatch({
          type: 'markNotificationsAsRead',
          notificationId: item.id
        }).then(
          () => {
            window.open(item.url, '_self')
          })
      } else {
        window.open(item.url, '_self')
      }
    }

    /**
     * @description Execute the action required when a new page is selected
     * @param pageNumber Number of the page selected
     */
    function goToPage (pageNumber) {
      document.querySelector('#app #app').scrollTo(0, 0)
      updateStorePagination(pageNumber)
      store.commit({
        type: 'UPDATE_NOTIFICATIONS_URL'
      })
      loadNotifications()
    }

    /**
     * @description Sets values of pagination in the store.
     * @param page number of current page.
     * @param size number of results per page
     */
    function updateStorePagination (page = 1, size) {
      store.commit({
        type: 'SET_NOTIFICATIONS_PAGINATION',
        size: size || pageSize.value,
        page
      })
    }

    /**
     * @description Calls service to mark as read the selected notifications.
     */
    function markSelectedAsRead () {
      store.dispatch({
        type: 'markSelectedNotificationsAsRead'
      }).then(
        (response) => {
          if (response.data.success) {
            notifications.value.map(notification => {
              if (store.state.areAllNotificationsSelected) {
                if (!store.state.notificationsIdsToAction.find(id => notification.id === id)) {
                  notification.isRead = true
                }
              } else {
                if (store.state.notificationsIdsToAction.find(id => notification.id === id)) {
                  notification.isRead = true
                }
              }
            })
            store.commit({
              type: 'CLEAN_NOTIFICATIONS_IDS_TO_ACTION'
            })
          }
        })
    }

    /**
     * @description Delete selected notifications and refresh page
     */
    function deleteSelected () {
      notificationsLoaded.value = false
      store.dispatch({
        type: 'deleteNotifications'
      }).then(() => {
        delayedGetNotifications()
      })
    }

    /**
     * @description Action for clicking on card's checkbox to mark/unmark
     */
    function handleSelectClick (isSelected, item) {
      store.commit({
        type: 'TOGGLE_NOTIFICATION_SELECTION',
        item: item,
        isSelected: isSelected
      })
    }

    /**
     * @description Action for selecting filter of source or status
     */
    function filterSelected () {
      store.commit({
        type: 'TOGGLE_ALL_NOTIFICATIONS_SELECTION',
        areAllNotificationsSelected: false,
        totalNotifications: 0
      })
      store.commit({
        type: 'CLEAN_NOTIFICATIONS_IDS_TO_ACTION'
      })
      goToPage(1)
    }

    return {
      store,
      notificationsLoaded,
      notifications,
      openDetailPage,
      notificationsTotal,
      maxPages,
      goToPage,
      options,
      markSelectedAsRead,
      handleSelectClick,
      itemIsSelected,
      delayedGetNotifications,
      deleteSelected,
      filterSelected
    }
  }
}
</script>
