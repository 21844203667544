<template>
  <section class="containerMenu containerFilterPerAppNotifications" v-click-away="clickOutside">
    <span class="containerMenu__label">Results from </span>
    <button class="containerMenu__button" @click="toogleMenu">
      <span class="containerMenu__button--label">{{filterLabelTitle(selectedFilter)}}</span>
      <span class="grey-icon-arrow-minimize" :class="{'opened': toogleFilterPerApp}"></span>
    </button>
    <transition enter-active-class="menuAnimationIn" leave-active-class="menuAnimationOut">
      <ul class="containerMenu__list" v-if="toogleFilterPerApp">
        <li class="containerMenu__list--option" v-for="(item, index) in messages.filterPerApp"
            :key="index" @click="selectFilterPerApp(item, true)" v-show="item.title !== selectedFilter"
            :class="{'noMobile': !store.state.isMobile}">
          {{filterLabelTitle(item.title)}}
        </li>
      </ul>
    </transition>
  </section>
</template>

<script>
import { onMounted, ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import filterPerApp from './filterPerAppNotifications.json'

export default {
  name: 'filterPerAppNotifications',
  emits: ['launch-search'],
  setup (props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const messages = reactive(filterPerApp)
    const toogleFilterPerApp = ref(false)
    const selectedFilter = ref(null)

    onMounted(() => {
      setUpFilterPerApp()
    })

    /**
     * @description Filter label in order to show on view (Keeping original label on under process)
     * @param title Source
     */
    const filterLabelTitle = computed(() => (title) => {
      return title === 'Office365' ? 'Office 365' : title
    })

    /**
     * @description Sets initial configuration as source shown by default.
     */
    function setUpFilterPerApp () {
      const defaultSource = messages.filterPerApp.find(message => message.title.toLowerCase() === 'all')
      if (defaultSource) {
        selectFilterPerApp(defaultSource, false)
      } else {
        selectedFilter.value = route.query.s || store.state.sourceNotifications
      }
    }

    /**
     * @description Show or hide menu
     */
    function toogleMenu () {
      toogleFilterPerApp.value = !toogleFilterPerApp.value
    }

    /**
     * @description Hide menu whenever user click outside of component
     */
    function clickOutside () {
      toogleFilterPerApp.value = false
    }

    /**
     * @description Event when user select a filter
     * @param item Option selected by user (JSON)
     * @param launchSearch flag to indicate if a new search needs to be made.
     * @event dispatch getResultsSearch to get data
     */
    function selectFilterPerApp (item, launchSearch) {
      selectedFilter.value = item.title
      if (launchSearch) toogleMenu()

      store.commit({
        type: 'SET_SOURCE_TO_NOTIFICATIONS',
        source: item.param
      })

      if (launchSearch) {
        emit('launch-search')
      }
    }

    return {
      store,
      route,
      messages,
      toogleFilterPerApp,
      selectedFilter,
      toogleMenu,
      clickOutside,
      selectFilterPerApp,
      filterLabelTitle
    }
  }
}
</script>
